export const TRANSACTION_TABLE_HEAD = [
    {
        id: 'customer',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'plan',
        numeric: false,
        disablePadding: false,
        label: 'Plan name',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'amount',
        numeric: false,
        disablePadding: false,
        label: 'Amount',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'payment',
        numeric: false,
        disablePadding: false,
        label: 'Payment method',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
        align: 'center',
        isSortable: false,
    },
  ];

  export const CLIENT_LIST_TABLE_HEAD = [
    {
        id: '',
        label: '',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'name',
        label: 'Name',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'email',
        label: 'Email',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'number',
        label: 'Number',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'status',
        label: 'Subscription Status',
        align: 'left',
        isSortable: true,
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'left',
        isSortable: false,
    },
  ];

  export const CLIENT_LIST_SUB_TABLE_HEAD = [
    {
        id: 'name',
        label: 'Name',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'address',
        label: 'Address',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'email',
        label: 'Email',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'website',
        label: 'Website',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'category',
        label: 'Category',
        align: 'left',
        isSortable: false,
    },
    {
        id: 'number',
        label: 'Number',
        align: 'left',
        isSortable: false,
    },
  ];