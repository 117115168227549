import { Grid } from "@mui/material";
import React from "react";

const ResponsiveFormGrid = ({ children, gap, props }) => {
  console.log(children);
  return (
    <Grid
      display={"flex"}
      flexDirection={{
        sm: "column",
        xs: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      gap={gap}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default ResponsiveFormGrid;
