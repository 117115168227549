import {
  Divider,
  Grid,
  Pagination,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import React from "react";
import Spacer from "./Spacer";

const CommonTableFooter = ({
  tableData,
  totalPages,
  pageNumber,
  setPageNumber,
}) => {
  return (
    <>
      {tableData && tableData?.length > 0 ? (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={12}>
              <Divider />
              <Spacer space={2} />
              <Grid
                display={"flex"}
                flex={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Pagination
                  count={totalPages}
                  page={pageNumber}
                  color="primary"
                  onChange={(e, v) => setPageNumber(v)}
                />
              </Grid>
            </TableCell>
          </TableRow>
        </TableFooter>
      ) : null}
    </>
  );
};

export default CommonTableFooter;
