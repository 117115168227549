import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CustomLegend = ({ legend, children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{ border: "1px solid lightgray", borderRadius: "4px", p: 2 }}
    >
      {legend && (
        <Typography
          variant="h6"
          component="legend"
          sx={{
            position: "relative",
            top: "-25px",
            bgcolor: "white",
            px: 1,
            width: "max-content",
          }}
        >
          {legend}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default CustomLegend;
