import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';

const ShiftList = ({ shifts, onDelete }) => {


  return (
    <List>
      {shifts.map((shift) => (
        <ListItem key={shift.id}>
          <ListItemText
            primary={shift.title}
            secondary={`${shift.start} - ${shift.end}`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(shift.id)}>
              delete
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default ShiftList;
