import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Spacer from "../Spacer";

const ConfirmationDialog = ({
  open,
  handleClose,
  message,
  action,
  actionText,
  disabled,
  subtitle,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent sx={{ paddingTop: 5 }}>
        <Box textAlign={"center"}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            align="center"
          >
            {message}
          </Typography>
          <Spacer space={1}></Spacer>
          <Typography id="modal-modal-title" variant="body2" align="center">
            {subtitle && subtitle}
          </Typography>
          <Spacer space={3}></Spacer>
          <Stack direction={"row"} justifyContent={"center"} spacing={2}>
            <Button onClick={handleClose} size="medium" variant="contained">
              Cancel
            </Button>
            <Button
              size="medium"
              variant="contained"
              color="error"
              onClick={action}
              disabled={disabled}
              sx={{ minWidth: 85 }}
            >
              {actionText}
            </Button>
          </Stack>
          <Spacer space={2}></Spacer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
