import React, { useEffect } from "react";
import { Navigate } from "react-router";
import store from "src/store/Store";

const HasAccess = ({ allowedRoles, children }) => {
  let authentication = store.getState().authentication;
  useEffect(() => {}, [authentication?.user?.role]);

  if (!authentication.isAuthenticated) {
    return <Navigate to={"/login"} replace />;
  } else if (!allowedRoles.includes(authentication?.user?.role)) {
    return <Navigate to={"/404"} replace />;
  }
  return <>{children}</>;
};

export default HasAccess;
