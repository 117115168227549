import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { CLIENT_LIST_SUB_TABLE_HEAD } from "src/common/HeaderName";
import CommonTableHeader from "src/common/CommonTableHeader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router";
import ConfirmationDialog from "src/common/dialog/ConfirmationDialog";
import { REQUEST_DELETE_CLIENT } from "src/servieces/superadmin";
import { getBody } from "src/servieces";
import { showSnackbar } from "src/store/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";

const ClientTableRow = ({ item, i, getClientsLits }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleDeleteDialog = () => {
    setIsDeleteOpen(true);
  };
  const handleCloseDelete = () => {
    setIsDeleteOpen(false);
  };

  const deleteClient = (id) => {
    REQUEST_DELETE_CLIENT(id)
      .then((res) => {
        res = getBody(res);
        if (res.code == 200) {
          console.log(res);
          handleCloseDelete();
          getClientsLits();
          dispatch(showSnackbar({message:res.message,variant:'success'}))
        }
      })
      .catch((error) => {
        dispatch(showSnackbar({message:error.response.data.message,variant:'error'}))
      });
  };
  return (
    <>
      <TableRow key={i}>
        <TableCell width={"5%"}>
          <Grid>
            {open ? (
              <IconButton onClick={handleOpen}>
                <KeyboardArrowUpIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleOpen}>
                <KeyboardArrowDownIcon />
              </IconButton>
            )}
          </Grid>
        </TableCell>
        <TableCell width={"20%"}>
          <Grid display={"flex"} gap={2} alignItems={"center"}>
            <Avatar />
            <Grid>
              <Typography variant="h6">{item?.owner_name || "-"}</Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell width={"20%"}>
          <Grid>
            <Typography variant="subtitle1">
              {item?.owner_email || "-"}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell width={"15%"}>
          <Typography variant="subtitle1">
            {item?.owner_contact_number || "-"}
          </Typography>
        </TableCell>
        <TableCell width={"20%"}>
          {item?.owner_subscription_status && (
            <Chip
              label={
                <Typography textTransform={"capitalize"}>
                  {item?.owner_subscription_status}
                </Typography>
              }
              color={
                item?.owner_subscription_status == "active"
                  ? "success"
                  : "error"
              }
            ></Chip>
          )}
        </TableCell>
        <TableCell width={"20%"}>
          <Grid display={"flex"} gap={2}>
            <Button onClick={() => navigate(`/super-admin/clients/edit`)}>
              Edit
            </Button>
            <Button color="error" onClick={handleDeleteDialog}>
              Delete
            </Button>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  mt: 2,
                  backgroundColor: (theme) => theme.palette.grey.A200,
                  p: "5px 15px",
                  color: (theme) =>
                    `${
                      theme.palette.mode === "dark"
                        ? theme.palette.grey.A200
                        : "rgba(0, 0, 0, 0.87)"
                    }`,
                }}
              >
                Company Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <CommonTableHeader headLabel={CLIENT_LIST_SUB_TABLE_HEAD} />
                <TableBody>
                  <TableRow>
                    <TableCell width={"20%"}>
                      <Typography color="textSecondary" fontWeight="400">
                        {item?.company_name || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell width={"20%"}>
                      <Typography color="textSecondary" fontWeight="400">
                        {item?.company_address || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell width={"20%"}>
                      <Typography color="textSecondary" fontWeight="400">
                        {item?.company_email || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell width={"20%"}>
                      <Typography color="textSecondary" fontWeight="400">
                        {item?.company_website || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell width={"20%"}>
                      <Typography fontWeight="600">
                        {item?.company_category || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell width={"20%"}>
                      <Typography fontWeight="600">
                        {item?.company_contact_number || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={isDeleteOpen}
        actionText={"Ok"}
        handleClose={handleCloseDelete}
        message={"Are you sure you want to delete this client?"}
        action={() => deleteClient(item?.id)}
        subtitle={
          "With this action all data related to client will be deleted!"
        }
      />
    </>
  );
};

export default ClientTableRow;
