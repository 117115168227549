import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// authentication
const Login2 = Loadable(lazy(() => import('../views/authentication/auth2/Login2')));
const Register2 = Loadable(lazy(() => import('../views/authentication/auth2/Register2')));
const ForgotPassword2 = Loadable(
  lazy(() => import('../views/authentication/auth2/ForgotPassword2')),
);
const TwoSteps2 = Loadable(lazy(() => import('../views/authentication/auth2/TwoSteps2')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

// Transaction route
const Transactions = Loadable(lazy(() => import('../views/pages/transactions/Transactions')));

// landingpage
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));

//Dashboards
const AdminDashboard = Loadable(lazy(() => import('../views/pages/dashboard/AdminDashboard')));
const ClientsDashboard = Loadable(lazy(() => import('../views/pages/dashboard/ClientsDashboard')));
const EmployeeDashboard = Loadable(lazy(() => import('../views/pages/dashboard/EmployeeDashboard')));

import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
import ShiftCalendar from 'src/views/apps/calendar/ShiftCalender';
import HasAccess from 'src/guards/HasAccess';
import ClientsList from 'src/views/pages/clients/ClientsList';
import EditClient from 'src/views/pages/clients/EditClient';

const roles = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  USER: 'staff',
};

const Router = [
  {
    path: '/super-admin',
    element: <AuthGuard isSuperAdmin={true}><FullLayout /></AuthGuard>,
    children: [
      { path: '/super-admin/dashboard', element: <HasAccess allowedRoles={[roles.SUPER_ADMIN]}><AdminDashboard/></HasAccess>},
      { path: '/super-admin/shifts', element: <ShiftCalendar/>},
      { path: '/super-admin/clients', element: <ClientsList/>},
      { path: '/super-admin/clients/edit', element: <EditClient/>},

      { path: '*', element: <Navigate to="/404" /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '/super-admin/transaction', element: <Transactions/>},

    ],
  },
  {
    path: '/admin',
    element: <AuthGuard isAdmin={true}><FullLayout /></AuthGuard>,
    children: [
      { path: '/admin/dashboard', element: <HasAccess allowedRoles={[roles.ADMIN]}><ClientsDashboard/></HasAccess>},
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <AuthGuard isStaff={true}><FullLayout /></AuthGuard>,
    children: [
      { path: '/dashboard', element: <HasAccess allowedRoles={[roles.USER]}><EmployeeDashboard/></HasAccess> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <GuestGuard><BlankLayout /></GuestGuard>,
    children: [
      { path: '/404', element: <Error /> },
      { path: '/super-admin/login', element: <Login2 isSuperAdmin={true}/> },
      { path: '/admin/login', element: <Login2 isAdmin={true}/> },
      { path: '/login', element: <Login2 isStaff={true}/> },
      { path: '/admin/register', element: <Register2 />},
      { path: '/forgot-password', element: <ForgotPassword2 /> },
      { path: '/admin/two-steps', element: <TwoSteps2 /> },
      { path: '/maintenance', element: <Maintenance /> },
      { path: '/landingpage', element: <Landingpage /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default Router;
