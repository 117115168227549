import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import React from "react";
import {
  IconFilter,
  IconSearch,
} from "@tabler/icons";

const CommonTableToolbar = (props) => {
  const { handleSearch, search } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Box sx={{ flex: "1 1 100%" }}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch size="1.1rem" />
              </InputAdornment>
            ),
          }}
          placeholder="Search..."
          size="small"
          onChange={handleSearch}
          value={search}
        />
      </Box>
      <Tooltip title="Filter list">
        <Button variant="outlined" startIcon={<IconFilter size="1.2rem" icon="filter" />}>
          Filter
        </Button>
      </Tooltip>
    </Toolbar>
  );
};

export default CommonTableToolbar;
