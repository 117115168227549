import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSnackbarOpen: false,
  displaySnackbarMessage: '',
  snackbarVariant: 'success', 
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
        console.log(action)
      state.isSnackbarOpen = true;
      state.displaySnackbarMessage = action.payload.message;
      state.snackbarVariant = action.payload.variant || 'success';
    },
    clearSnackbar: (state) => {
      state.isSnackbarOpen = false;
      state.displaySnackbarMessage = '';
      state.snackbarVariant = 'null';
    },
  },
});

export const { showSnackbar, clearSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
