import { Grid, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

const CommonTableNoData = () => {
  return (
    <TableRow>
      <TableCell colSpan={12}>
        <Grid padding={3}>
          <Typography variant="h6" align="center">
            No Data Found
          </Typography>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default CommonTableNoData;
