import { createSlice } from "@reduxjs/toolkit";

let user = JSON.parse(localStorage.getItem("user"));
const access_token = localStorage.getItem("access_token");
const admin_access_token = localStorage.getItem("admin_access_token");
const employee_access_token = localStorage.getItem("employee_access_token");

const initialState = user
  ? {
      user,
      isAuthenticated: true,
      access_token,
      admin_access_token,
      employee_access_token,
      error: null,
      loading: false,
    }
  : {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.access_token = action.payload?.access_token
        ? action.payload.access_token
        : null;
      state.admin_access_token = action?.payload?.admin_access_token
        ? action.payload.admin_access_token
        : null;
      state.employee_access_token = action.payload?.employee_access_token
        ? action.payload.employee_access_token
        : null;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
      state.access_token = null;
      state.admin_access_token = null;
      state.employee_access_token = null;
      localStorage.clear();
    },
    registerRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    registerSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.admin_access_token = action?.payload?.admin_access_token
        ? action.payload.admin_access_token
        : null;
      state.error = null;
    },
    registerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  logout,
} = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectError = (state) => state.auth.error;
export const selectLoading = (state) => state.auth.loading;

export default authSlice.reducer;
