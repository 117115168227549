import * as React from "react";
import { Snackbar, IconButton, Stack} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar } from "src/store/snackbar/snackbarSlice";

export default function SimpleSnackbar() {
	const dispatch = useDispatch();

	const { displaySnackbarMessage, isSnackbarOpen, snackbarVariant } = useSelector(
		state => state.snackbarReducer
	);

	function handleClose() {
		dispatch(clearSnackbar());
	}

	React.useEffect(() => {
		if(isSnackbarOpen === true) {
			setTimeout(handleClose, 6000)
		}

	}, [isSnackbarOpen]);
	const action = (
		<>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	return (
		<div>
			<Stack direction="column">
				<Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={isSnackbarOpen} onClose={handleClose} message={displaySnackbarMessage} action={action} variant={snackbarVariant}/>
				</Stack>
		</div>
	);
}