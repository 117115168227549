import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const EditPageHeader = ({ header, action }) => {
  return (
    <Grid marginY={2}>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <IconButton onClick={action}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">{header}</Typography>
      </Stack>
    </Grid>
  );
};

export default EditPageHeader;
