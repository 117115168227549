// @mui
import {
    TableRow,
    TableCell,
    TableHead,
    TableSortLabel,
    Box,
    Checkbox
  } from "@mui/material";
  
  // ----------------------------------------------------------------------
  
  const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: "1px",
    height: "1px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    clip: "rect(0 0 0 0)",
  };
  
  // ----------------------------------------------------------------------
  
  const CommonTableHeader = ({ headLabel, sx, order, orderBy, onSort,isSelectable,numSelected = 0,rowCount=0,onSelectAllRows,setOrderBy,setColumn }) => {
    return (
      <TableHead sx={sx}>
        <TableRow>
        {isSelectable && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(event) => onSelectAllRows(event.target.checked)}
              />
            </TableCell>
          )}
          {headLabel.map((headCell,index) => (
            <TableCell
              key={headCell.id}
              align={headCell.align || "left"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ width: headCell.width, minWidth: headCell.minWidth }}
            >
              {headCell.isSortable ? (
                <TableSortLabel
                  hideSortIcon
                  active={headCell.id ? true : false}
                  direction={
                    orderBy === headCell.id
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  }
                  onClick={() => {setOrderBy(orderBy === headCell.id
                    ? order === "asc"
                      ? "desc"
                      : "asc"
                    : "asc");setColumn(index);onSort(headCell.id)}}
                  sx={{ textTransform: "capitalize" }}
                >
                  {headCell.label}
  
                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  
  export default CommonTableHeader;