import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTableHeader from "src/common/CommonTableHeader";
import CommonTableToolbar from "src/common/CommonTableToolbar";
import { CLIENT_LIST_TABLE_HEAD } from "src/common/HeaderName";
import PageContainer from "src/components/container/PageContainer";
import Scrollbar from "src/components/custom-scroll/Scrollbar";
import useTable from "src/hooks/useTable";
import CommonTableFooter from "src/common/CommonTableFooter";
import { REQUEST_GET_CLIENTS_LIST } from "src/servieces/superadmin";
import ClientTableRow from "./section/ClientTableRow";
import { getBody } from "src/servieces";
import CommonTableNoData from "src/common/CommonTableNoData";
import { showSnackbar } from "src/store/snackbar/snackbarSlice";

const ClientsList = () => {
  const { order, orderBy, onSort } = useTable();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  // const [OrderBy, setOrderBy] = useState(order);
  // const [perPage, setPerPage] = useState(5);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [loading, setIsLoading] = useState(true);
  // const [column, setColumn] = useState(1);

  const getProducts = useSelector((state) => state.ecommerceReducer.products);
  const [search, setSearch] = React.useState("");
  const handleSearch = (event) => {
    const filteredRows = getProducts.filter((row) => {
      return row.title.toLowerCase().includes(event.target.value);
    });
    setSearch(event.target.value);
    console.log(filteredRows)
  };

  const getClientsLits = () => {
    REQUEST_GET_CLIENTS_LIST()
      .then((res) => {
        res = getBody(res);
        setIsLoading(false);
        if (res.code == 200) {
          setTableData(res.data); 
        }
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(showSnackbar({message:error.response.data.message,variant:'error'}))
      });
  };

  useEffect(() => {
    getClientsLits();
  }, []);

  return (
    <PageContainer title="Clients Table" description="Clients list">
      <CommonTableToolbar handleSearch={handleSearch} search={search} />
      <Scrollbar
        sx={{
          height: { lg: "calc(100vh - 210px)", md: "100vh" },
          maxHeight: "800px",
        }}
      >
        <Paper variant="outlined">
          <Box mb={2} sx={{ mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <CommonTableHeader
                  headLabel={CLIENT_LIST_TABLE_HEAD}
                  onSort={onSort}
                  order={order}
                  orderBy={orderBy}
                  // setOrderBy={setOrderBy}
                  // setColumn={setColumn}
                />
                <TableBody>
                  {!loading &&
                    tableData.map((item, i) => (
                      <ClientTableRow
                        key={i}
                        item={item}
                        i={i}
                        getClientsLits={getClientsLits}
                      />
                    ))}
                  {!loading && (tableData.length == 0 || !tableData) && (
                    <CommonTableNoData />
                  )}
                  {loading &&
                    Array(5)
                      .fill(1)
                      .map((item, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell width={"20%"}>
                            <Skeleton />
                          </TableCell>
                          <TableCell width={"20%"}>
                            <Skeleton />
                          </TableCell>
                          <TableCell width={"20%"}>
                            <Skeleton />
                          </TableCell>
                          <TableCell width={"20%"}>
                            <Skeleton />
                          </TableCell>
                          <TableCell width={"10%"}>
                            <Skeleton />
                          </TableCell>
                          <TableCell width={"20%"}>
                            <Grid display={"flex"} gap={2}>
                              <Skeleton width={70} />
                              <Skeleton width={70} />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
                {!loading && tableData.length > 0 && (
                  <CommonTableFooter
                    totalPages={1}
                    pageNumber={1}
                    tableData={tableData}
                  />
                )}
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Scrollbar>
    </PageContainer>
  );
};

export default ClientsList;
