import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

let controller = new AbortController();
export const GET = (route, params = {}) => {
  let employeeToken = localStorage.getItem("employee_access_token");
  let adminToken = localStorage.getItem("admin_access_token");
  let access_token = localStorage.getItem("access_token");
  let headers = {
    Authorization: `Bearer ${
      route.includes("api/super-admin/")
        ? access_token
        : route.includes("api/admin/")
        ? adminToken
        : employeeToken
    }`,
    "device-type": "web",
    ...(adminToken &&
      employeeToken &&
      access_token && {
        "employee-access-token": employeeToken,
        "admin-access-token": adminToken,
        "access-token": access_token,
      }),
  };

  return axios.get(route, { params, headers });
};

export const POST = (route, params = {}, stoppable = false) => {
  let employeeToken = localStorage.getItem("employee_access_token");
  let adminToken = localStorage.getItem("admin_access_token");
  let access_token = localStorage.getItem("access_token");
  if (
    [
      `${API_URL}/employee/login`,
      `${API_URL}/super-admin/login`,
      `${API_URL}/admin/password/reset`,
      `${API_URL}/admin/login`,
      `${API_URL}/admin/register`,
      `${API_URL}/admin/forgot-password`,
    ].includes(route)
  ) {
    const headers = {
      "device-type": "web",
    };
    return axios.post(route, params, { headers: headers });
  } else {
    let headers = {
      Authorization: `Bearer ${
        route.includes("api/super-admin/")
          ? access_token
          : route.includes("api/admin/")
          ? adminToken
          : employeeToken
      }`,
      "device-type": "web",
      ...(adminToken &&
        employeeToken &&
        access_token && {
          "employee-access-token": employeeToken,
          "admin-access-token": adminToken,
          "access-token": access_token,
        }),
    };

    if (stoppable) {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();
      headers = { ...headers, signal: controller.signal };

      return axios.post(route, params, {
        headers: headers,
        signal: controller.signal,
      });
    }
    return axios.post(route, params, { headers: headers });
  }
};

export const DELETE = (route, params = {}) => {
  let employeeToken = localStorage.getItem("employee_access_token");
  let adminToken = localStorage.getItem("admin_access_token");
  let access_token = localStorage.getItem("access_token");
  let headers = {
    Authorization: `Bearer ${
      route.includes("api/super-admin/")
        ? access_token
        : route.includes("api/admin/")
        ? adminToken
        : employeeToken
    }`,
    "device-type": "web",
    ...(adminToken &&
      employeeToken &&
      access_token && {
        "employee-access-token": employeeToken,
        "admin-access-token": adminToken,
        "access-token": access_token,
      }),
  };

  return axios.delete(route, { params, headers });
};


export const getBody = (response) => {
  // if (response.response.status === 503) {
  // 	window.location.href = '/maintenance';
  // } else {
  if (response.message && response.message === "Network Error") {
    console.log('Network Error')
  } else {

    if ("response" in response && response.response.data.code === 401) {
      window.location.href = "/login";
      localStorage.clear();
    }
    return response.data;
  }
  // }
};
