import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

const roles = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  USER: 'staff',
};
export default function GuestGuard({ children }) {
  const authentication = useSelector(state => state.authentication)
  // here get isAuthenticated from redux
if(authentication.isAuthenticated){
  switch (authentication.user.role) {
    case roles.SUPER_ADMIN:
      return <Navigate to={'/super-admin/dashboard'} />;
    case roles.ADMIN:
      return <Navigate to={'/admin/dashboard'} />;
    case roles.USER:
      return <Navigate to={'/dashboard'} />;
    default:
      return <Navigate to={'/404'} />;
  }}

  return <>{children}</>;
}