import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import CustomLegend from "src/common/CustomLegend";
import Spacer from "src/common/Spacer";
import EditPageHeader from "src/common/forms/EditPageHeader";
import ResponsiveFormGrid from "src/common/forms/ResponsiveFormGrid";
import PageContainer from "src/components/container/PageContainer";
import CustomTextField from "src/components/forms/theme-elements/CustomTextField";

const EditClient = () => {
  const navigate = useNavigate();
  return (
    <PageContainer title="Edit Client">
      <EditPageHeader
        header={"Edit Clients"}
        action={() => navigate("/super-admin/clients")}
      />
      <Container maxWidth="xl">
        <Formik
          initialValues={{
            owner_name: "",
            owner_email: "",
            owner_number: "",
            company_name: "",
            company_email: "",
            company_number: "",
            country: "",
            zip_code: "",
            state: "",
            category: "",
            city: "",
            description: "",
            address: "",
            website: "",
            code: "",
          }}
          enableReinitialize={true}
          validateOnChange={true}
          onSubmit={async (values) => {
            console.log(values);
          }}
        >
          {({
            handleChange,
            handleReset,
            handleSubmit,
            values,
          }) => (
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <ResponsiveFormGrid gap={5}>
                <Grid display={"flex"} justifyContent={"center"}>
                  <Avatar sx={{ height: 200, width: 200 }} />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    flex: 2,
                  }}
                >
                  <CustomLegend legend={"Owner Detais"}>
                    <ResponsiveFormGrid gap={2}>
                      <CustomTextField
                        label="Owner Name"
                        fullWidth
                        name="owner_name"
                        value={values.owner_name}
                        onChange={handleChange}
                      />
                      <CustomTextField
                        label="Owner Email"
                        fullWidth
                        name="owner_email"
                        value={values.owner_email}
                        onChange={handleChange}
                      />
                      <CustomTextField
                        label="Contact Number"
                        fullWidth
                        name="owner_number"
                        value={values.owner_number}
                        onChange={handleChange}
                      />
                    </ResponsiveFormGrid>
                  </CustomLegend>
                  <CustomLegend legend={"Company Detais"}>
                    <Grid display={"flex"} flexDirection={"column"} gap={2}>
                      <ResponsiveFormGrid gap={2}>
                        <CustomTextField
                          label="Company Name"
                          fullWidth
                          name="company_name"
                          value={values.company_name}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          label="Company Email"
                          fullWidth
                          name="company_email"
                          value={values.company_email}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          label="Contact Number"
                          fullWidth
                          name="company_number"
                          value={values.company_number}
                          onChange={handleChange}
                        />
                      </ResponsiveFormGrid>
                      <ResponsiveFormGrid gap={2}>
                        <CustomTextField
                          label="Company Code"
                          fullWidth
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          label="Company Website"
                          fullWidth
                          name="website"
                          value={values.website}
                          onChange={handleChange}
                        />

                        <Autocomplete
                          disablePortal
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              padding: "6px",
                            },
                          }}
                          id="combo-box-demo"
                          options={[
                            "Food & Drinks",
                            "IT",
                            "PharmaCeutical",
                            "Oil & Gas",
                          ]}
                          renderInput={(params) => (
                            <CustomTextField {...params} label="Category" />
                          )}
                          fullWidth
                        />
                      </ResponsiveFormGrid>
                      <ResponsiveFormGrid gap={2}>
                        <CustomTextField
                          label="City"
                          fullWidth
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          label="State"
                          fullWidth
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          label="Zip Code"
                          fullWidth
                          name="zip_code"
                          value={values.zip_code}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          label="Country"
                          fullWidth
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        />
                      </ResponsiveFormGrid>
                      <ResponsiveFormGrid gap={2}>
                        <CustomTextField
                          multiline
                          rows={2}
                          label="Company Description"
                          fullWidth
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          multiline
                          rows={2}
                          label="Address"
                          fullWidth
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                        />
                      </ResponsiveFormGrid>
                    </Grid>
                  </CustomLegend>
                  <Stack direction={"row"} justifyContent={"end"} spacing={2}>
                    <Button
                      size="medium"
                      variant="contained"
                      sx={{ width: 150 }}
                      color="error"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <Button
                      size="medium"
                      variant="contained"
                      sx={{ width: 150 }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Stack>
                  <Spacer space={2}></Spacer>
                </Box>
              </ResponsiveFormGrid>
            </Form>
          )}
        </Formik>
      </Container>
    </PageContainer>
  );
};

export default EditClient;
