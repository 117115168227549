const API_URL = process.env.REACT_APP_API_URL;
let addPrefix = (route) => {
    return API_URL + "/" + route;
};

//Auth
export const ROUTE_LOGIN_SUPER_ADMIN = addPrefix('super-admin/login')
export const ROUTE_LOGIN_ADMIN = addPrefix('admin/login')
export const ROUTE_LOGIN_EMPLOYEE= addPrefix('employee/login')
export const ROUTE_REGISTER_ADMIN = addPrefix('admin/register')
export const ROUTE_LOGOUT_SUPER_ADMIN = addPrefix('super-admin/logout')

//Super Admin
export const ROUTE_GET_CLIENTS_LIST = addPrefix('super-admin/admins')
export const ROUTE_DELETE_CLIENT = addPrefix('super-admin/admins/')